import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../context/AuthContext";
import { useCookie } from "./useCookie";


export const AuthProvider = ({ children }) => {
    const [user, setUser] = useCookie("user", null);
    const navigate = useNavigate();

    // Call this function to signup new user
    const signup = async (username, email, password, setIsValidCredentials, setShowSpinner, setIsUsedEmail, setIsUsedUsername) => {
        const url = "https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/user";

        const requestData = {
            username: username,
            // firstName: firstName,
            // lastName: lastName,
            password: password,
            email: email,
        };

        const headers = {
            "Content-Type": "application/json",
        };

        axios
            .post(url, requestData, { headers })
            .then(( response ) => {
                login(email, password, setIsValidCredentials, setShowSpinner);
            })
            .catch((error) => {
                if (error.response.status === 432) {
                    setIsUsedEmail(true);
                }
                if (error.response.status === 433) {
                    setIsUsedUsername(true);
                }
                console.error("Error: ", error);
                setShowSpinner(false);
            })
    }

    // call this function when you want to authenticate the user
    const login = async (email, password, setIsValidCredentials, setShowSpinner) => {
        const url = "https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/users";

        const requestData = {
            email: email,
            password: password
        };
        const headers = {
            "Content-Type": "application/json",
        };

        axios
            .post( url, requestData, { headers })
            .then(( response) => {
                if ( response.status === 200 ) {
                    const data = response.data[0];
                    const userInfoObj = {
                        userName: data.username.S,
                        userEmail: data.email.S,
                        userId: data.uid.S,
                        walletBalance: data.usdBalance.N,
                        usdDeposits: data.usdDeposits.N,
                        walletId: data.walletId.S,
                        webhookId: data.webhookId.S,
                        // bearerToken: data.bearerToken.S,
                        dateJoined: data.dateJoined.S.split(" ").join(""), // Currently has a space in the date - "2023-08-10T 11:11:11" - Spliting at the space and combining into one string without spaces.
                        firstName: data.firstName.S,
                        lastName: data.lastName.S,
                        subscription: data.subscription.N,
                    };
                    
                    setUser(userInfoObj);

                    setIsValidCredentials(true);

                    navigate("/dashboard", {replace: true});
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (error.response.status === 404) {
                    setIsValidCredentials(false);
                    setShowSpinner(false);
                }
            });
        
    };

    const resetPassword = async (email, setShowSpinner, setShowOTPMessage) => {
        const url = "https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/user/password/forgot"

        const requestData = {
            email: email
        };
        const headers = {
            "Content-Type": "application/json",
        };

        axios
            .post(url, requestData, { headers })
            .then((response) => {
                if (response.status === 200){
                    // console.log(response);
                    setShowOTPMessage(true);
                    setShowSpinner(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                // setShowEmailError(true);
                setShowSpinner(false);
                setShowOTPMessage(true);
            });

    } 

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/login", { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            signup,
            login,
            logout,
            resetPassword,
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export const useAuth = () => {
    return useContext(AuthContext);
};