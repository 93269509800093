import React, {useRef, useState} from "react";

import "./BragCardModal.css";

import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import IosShareIcon from '@mui/icons-material/IosShare';

import Logo from "../primitives/Logo/Logo";
import Spinner from "../loadingAnimations/Spinner";


const BragCardModal = ({trade, setShowModal}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    const tokenId = trade.tokenId;
    const transactionSource = trade.txnSource;
    const profit = trade.profit;
    const exitPrice = trade.txnPrice;
    const entryPrice = exitPrice / (1 + (profit / 100));

    // Configuring Share Functionality for Brag Card using html-to-image and Web share API
    const imageRef = useRef(null);

    // const handleShare = async() => {
    //     setShowSpinner(true);
    //     try {
    //         // Convert div to image
    //         const dataUrl = await toPng(imageRef.current);

    //         // Create a canvas element
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');

    //         // Set canvas dimensions
    //         canvas.width = imageRef.current.offsetWidth;
    //         canvas.height = imageRef.current.offsetHeight;

    //         // Create an image element
    //         const img = new Image();

    //         // When image loads, draw it onto the canvas
    //         img.onload = () => {
    //             ctx.drawImage(img, 0, 0);
                
    //             // Copy canvas content to clipboard
    //             canvas.toBlob((blob) => {
    //                 navigator.clipboard.write([
    //                     new ClipboardItem({ 'image/png': blob })
    //                 ]).then(() => {
    //                     console.log('Copied to clipboard!');
    //                     setShowSpinner(false);
    //                     alert('Image copied to clipboard!');
    //                 }, (error) => {
    //                     console.error('Unable to write to clipboard. Error:', error);
    //                     alert('Something went wrong! Please contact support at support@chart.observer.')
    //                     setShowSpinner(false);
    //                 });
    //             });
    //         };

    //         // Set image source to data URL
    //         img.src = dataUrl;
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    const handleShare = async () => {
        setShowSpinner(true);
        try {
          const dataUrl = await toPng(imageRef.current);
    
          const blob = await (await fetch(dataUrl)).blob();
    
          await navigator.clipboard.write([
            new ClipboardItem({ 'image/png': blob })
          ]);
    
        //   console.log('Copied to clipboard!');
          alert('Image copied to clipboard!');
        } catch (error) {
          console.error('Error:', error);
          alert('Something went wrong! Please contact support at support@chart.observer.');
        } finally {
          setShowSpinner(false);
        }
    };

    return(
        <div className="bc-modal-overlay" onClick={() => setShowModal(false)}>
            <div className="bc-modal-container" ref={imageRef} onClick={(e) => e.stopPropagation()}>
                <div className="bc-modal">
                    <div className="bc-logo-wrapper">
                        <Logo isSideBarExpanded={true} isLandingPage={false} linkActive={false}/>
                        {showSpinner ? (
                            <Spinner spinnerSize="spinner-small"/>
                        ) : (
                            <IosShareIcon className="bc-modal-share-icon" onClick={handleShare}/>
                        )}
                    </div>
                    <div className="bc-header">
                        <h2 className="text-header bc-modal-token">{tokenId}</h2>
                        <p className="text-xsmall bc-method">{transactionSource}</p>
                    </div>
                    <h1 className={`text-hero-large bc-profit-${profit > 0 ? 'green' : 'red'}`}>{profit}%</h1>
                    <div className="bc-trade-detail-container">
                        <div className="bc-entry-wrapper">
                            <p className="text-base bc-trade-detail-title">Entry Price</p>
                            <p className="text-base bc-trade-detail-subtext">
                                {entryPrice < 1 ? `$${(entryPrice).toFixed(6)}` : entryPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                                
                            </p>
                        </div>
                        <div className="bc-exit-wrapper">
                            <p className="text-base bc-trade-detail-title">Exit Price</p>
                            <p className="text-base bc-trade-detail-subtext">
                                {parseFloat(exitPrice) < 1 ? `$${parseFloat(exitPrice).toFixed(6)}` : parseFloat(exitPrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                            </p>
                        </div>
                    </div>


                </div>
                <div className="bc-modal-referral">
                    <div className="bc-modal-referral-text">
                        <p className="text-sm">Join ChartObserver and earn 100 points</p>
                        <p className="text-base weight-semi-bold">Referral Code: CO123af</p>
                    </div>
                    <div className="bc-modal-qr">
                        <QRCode
                            size={256}
                            style={{ height: "50px", maxWidth: "50px", width: "50px" }}
                            value={"https://chart.observer"}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BragCardModal;