import React, { useState } from "react";
import "./TradeOrderInput.css";

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const TradeOrderInput = ({isReadOnly, tokenChoice, tokenPrice, userBalance, amount, isUSD, setIsUSD, handleAmountChange, isInputValid, setIsInputValid}) => {
    
    const [isInputActive, setIsInputActive] = useState(false);
    
    const inputClassName = isInputActive ? "trade-order-wrapper-active" : "trade-order-wrapper-inactive";
    
    const handleInputSelect = () => {
        setIsInputActive(!isInputActive);
    };

    const validateAmountChange = (event) => {
        const inputValue = event.target.value;
        // const inputRegex = /^(?!.*[-=+])\d*\.?\d+$/;
        // const inputRegex = /^\d*\.?\d*$/;
        const usdRegex = /^\$?(?:[0-9]+)?\.?[0-9]?[0-9]?$|^$/;
        const cryptoRegex = /^\$?(?:[0-9]+)?\.?[0-9]*$|^$/;
        const letterRegex = /[a-zA-Z]/;

        if (isUSD) {
            if (letterRegex.test(inputValue)) {
                return
            } else if (usdRegex.test(inputValue)) {
                setIsInputValid(true);
                handleAmountChange(inputValue); // Convert empty string to NaN, then to empty string
            } else {
                handleAmountChange(inputValue);
                setIsInputValid(false);
            }
        
            if (inputValue > userBalance) {
                setIsInputValid(false);
            }
        } else {
            if (letterRegex.test(inputValue)) {
                return
            } else if (cryptoRegex.test(inputValue)) {
                setIsInputValid(true);
                handleAmountChange(inputValue); // Convert empty string to NaN, then to empty string
            } else {
                handleAmountChange(inputValue);
                setIsInputValid(false);
            }

            if ((inputValue * tokenPrice) > userBalance) {
                setIsInputValid(false);
            }
        }
    }

    return(
        <div className="trade-information-container">
            <div className={isInputValid ? inputClassName : "trade-order-wrapper-invalid" }>
                <p className="trade-order-input-amount">AMOUNT</p>

                <input className="trade-order-input"
                    type={"text"}
                    value={amount}
                    onChange={validateAmountChange}
                    onFocus={handleInputSelect}
                    onBlur={handleInputSelect}
                    readOnly={isReadOnly}
                />

                <div className="trade-order-currency" onClick={() => {
                    setIsUSD(!isUSD);
                    handleAmountChange(0);
                }}>
                    <p className="trade-order-currency-swap">{isUSD ? "USD" : tokenChoice}</p>
                    <SwapHorizIcon fontSize="small"/>
                </div>
            </div>
                
            {isUSD &&
                <div className="trade-order-token-conversion">
                    <p>≈ {amount / tokenPrice} {tokenChoice}</p>
                </div>
            }
            {isReadOnly && 
                <p className="text-tiny trade-order-read-only-text">currently only supports selling entire holding amount</p>
            }
        </div>
    );
};

export default TradeOrderInput;