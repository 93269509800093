import React, { useEffect, useRef } from 'react';

import { useTheme } from '../../hooks/useTheme';

const TradingViewWidget = ({ tokenChoice }) => {
  const container = useRef();

  const { isDarkMode } = useTheme();

  useEffect(() => {

    // https://www.tradingview.com/widget/advanced-chart/
    const chartTheme = isDarkMode ? "dark" : "light";
    // Create a new script element
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "COINBASE:${tokenChoice}USD",
        "timezone": "Etc/UTC",
        "theme": "${chartTheme}",
        "style": "1",
        "locale": "en",
        "enable_publishing": false,
        "range": "1D",
        "calendar": false,
        "support_host": "https://www.tradingview.com"
      }`;

    // Check if the container ref is not null before accessing it
    if (container.current) {
      // Clear existing content
      container.current.innerHTML = '';
      // Append the script element to the container
      container.current.appendChild(script);
    }

    // Return a cleanup function to remove the script when component unmounts or tokenChoice changes
    return () => {
      // Check if the container ref is not null before accessing it
      if (container.current) {
        // Clear the container on unmount
        container.current.innerHTML = '';
      }
    };
  }, [tokenChoice, isDarkMode]);  // Run the effect whenever tokenChoice changes

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
    </div>
  );
}

export default TradingViewWidget;