import React from "react";
import "./styles/PageFive.css";

import CheckIcon from "@mui/icons-material/Check";
import NewsletterImage from "../assets/newsletter_image.png";
import Button from "../../../components/primitives/Button/Button";
import { useNavigate } from "react-router-dom";



const PageFive = () => {
  const navigate = useNavigate();
  return (
    <section id="page-five" className="page-five landing-page-padding">
      <div className="page-wrapper">
        <h1 className="page-five-header text-heading text-black">
          Get started for free. Pick a plan later.
        </h1>
        <div className="page-five-card-grid">
          <div className="plan">
            <div className="plan-price-container">
              <h2 className="black-name">Trial</h2>
              <div className="plan-price">
                <h1 className="blue-price">$0</h1>
                <p className="plan-price-month">/month</p>
              </div>
              <p className="plan-price-container-subtext">
                Get to know our platform for free
              </p>
              <hr />
            </div>

            <div className="plan-info">
              
              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">5 Trades per day</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Manual and automated trading</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">The frist 100 users get unlimited access</p>
              </div>
            </div>

            <div className="plan-button-container">
              <Button
                kind="plan"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
          {/* Basic Plan */}
          <div className="plan plan--blue">
            <div className="plan-price-container">
              <h2 className="white-name">Basic</h2>
              <div className="plan-price">
                <h1 className="white-price">$9.95</h1>
                <p className="plan-price-month-white">/month</p>
              </div>
              <p className="plan-price-container-subtext-white">
                Get to know our platform for free
              </p>
              <hr />
            </div>

            <div className="plan-info">
              <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  50 Trades per day
                </p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  Manual and automated trading
                </p>
              </div>

              {/* <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  Price data may be delayed 15 minutes
                </p>
              </div> */}
            </div>

            <div className="plan-button-container">
              <button className="plan-button-white">Coming soon</button>
            </div>
          </div>

          {/* Pro Trader Plan */}
          <div className="plan">
            <div className="plan-price-container">
              <h2 className="black-name">Pro Trader</h2>
              <div className="plan-price">
                <h1 className="blue-price">$49.85</h1>
                <p className="plan-price-month">/month</p>
              </div>
              <p className="plan-price-container-subtext">
                Get to know our platform for free
              </p>
              <hr />
            </div>

            <div className="plan-info">
              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">200 Trades per day</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Manual and automated trading</p>
              </div>

            </div>

            <div className="plan-button-container">
              <button className="plan-button-blue">Coming soon</button>
            </div>
          </div>
        </div>

        {/* News Letter */}
        <div className="news-letter">
          <div className="news-letter-content">
            <h1 className="text-heading text-dark-blue">
              Subscribe to our newsletter
            </h1>
            <p className="text-paragraph">
             Stay up to date with all the latest ChartObserver News and Promotions. Be the first to know when a new feature drops!
            </p>
            <div className="call-to-action">
              {/* <input placeholder="Enter email address" /> */}
              <Button kind="primary" hover={true} onClick={() => {window.open("https://docs.chart.observer/newsletter", '_blank', 'noopener,noreferrer')}}>
                Subscribe
              </Button>
            </div>
          </div>
          <img src={NewsletterImage} alt="newsletter" />
        </div>
      </div>
    </section>
  );
};

export default PageFive;
