/**
 * Takes in a list of trade objects and groups trades that have been opened and then closed
 * @param {list} trades - Raw trade data from ChartObs Back end. List of Trade Objects [{}, {}, ...}]
 * @param {bool} onlyClosedTrades - When True, will only show the groups of closed Trades. Used for displaying brag cards.
 * @returns {list} - Returns grouped trades that have been opened and closed. Some trades may still be open.
 */


const formatDate = (date) => {
    const dateObj = new Date(date.replace(/\s/g, ""));

    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day =  String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear()-2000;

    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const groupTrades = (trades, onlyClosedTrades = false) => {
    // Used to calculate the profit for each token sell. Loop through trades and add new key for each new token found, and sum values of trades under each key
    // Once a "sell is found, calculate and add profit to SELL trade, and delete this key to begin recording for next "grouping" of tokens' trades.
    let tokenProfitObject = {}

    const groupedTrades = [];

    var tradeIndex = 0;
    trades.forEach((trade) => {
        // Calculate profit for trades
        const tokenPair = trade.tokenPair.S;
        var profit = null;

        if (trade.entityType.S === "buy") {
            // if obj has the token key already, add new trade value to list
            if (tokenProfitObject.hasOwnProperty(tokenPair)) {
            tokenProfitObject[tokenPair].push( (trade.amount.N * trade.txnPrice.N) );
            } else {
            tokenProfitObject[tokenPair] = [(trade.amount.N * trade.txnPrice.N)]
            }
        }

        // Sum trade values for token and calculate profit. Delete key when done to start collecting data for the next trade.
        if (trade.entityType.S === "sell") {
            try {
            const sumOfTradeValues = tokenProfitObject[tokenPair].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            const sellValueOfTrade = (trade.amount.N * trade.txnPrice.N)
            profit = ( ( (sellValueOfTrade - sumOfTradeValues) / sumOfTradeValues ) * 100 ).toFixed(2);


            delete tokenProfitObject[tokenPair];
            } catch (error) {
            // Filters out duplicate sell orders
            return
            }

        }

        const tradeData = {
            id: tradeIndex,
            tokenId: tokenPair,
            entityType: trade.entityType.S,
            amount: trade.amount.N,
            date: formatDate(trade.txnDate.S),
            txnPrice: trade.txnPrice.N,
            total: (trade.txnPrice.N * trade.amount.N),
            closePrice: trade.hasOwnProperty("closePrice")
                ? trade.closePrice.N
                : null,
            tradeStatus: trade.isOpen.BOOL ? "open" : "closed",
            txnSource: trade.hasOwnProperty("txnSource")
                ? trade.txnSource.S
                : "Trading View",
            profit: profit,
        };

        if (onlyClosedTrades) {
            if (trade.entityType.S === "sell") {
                groupedTrades.push(tradeData);
            }
        } else {
            groupedTrades.push(tradeData);
        }
        tradeIndex++;
    });

    return groupedTrades;
}