import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

import "./Login.css";

import Spinner from "../../components/loadingAnimations/Spinner";
import Button from "../../components/primitives/Button/Button";
import Input from "../../components/primitives/Input/Input";

import { Navigate } from "react-router-dom";
import { validateEmail } from "../../helpers/emailValidation";
import { useAuth } from "../../hooks/useAuth";

import AuthLayout from "../../components/AuthLayout/AuthLayout";

const Login = () => {

    const { user, login } = useAuth();

    const navigate = useNavigate();

    const [showSpinner, setShowSpinner] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    // const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidCredentials, setIsValidCredentials] = useState(true);


    // Temp User Validation Flow
    const validateUser = async () => {
        // Validate email with regex
        if (validateEmail(email)) {
            setShowSpinner(true);
            setIsValidEmail(true);
            await login(email, password, setIsValidCredentials, setShowSpinner);
            
        } else {
            setIsValidEmail(false);
        }
    };


    if (user) {
        return <Navigate to="/dashboard" />;
    }

    return(
        <AuthLayout>
            <div className="login-page">
                <div className="login-wrapper">
                    <div className="login-form">
                        <div className="logo-container">
                            {/* <Logo size="6xl"/> */}
                            <h1 className="login-title">Log in</h1>
                        </div>
                        <div className="login-new-user">
                            <p className="login-new-user-text" onClick={() => navigate("/signup")}>Don't have an account? <span className="login-span">Sign up</span></p>
                        </div>
                        {/* Email */}
                        <div className="username-container">
                            {!isValidEmail && 
                                <ul>
                                    <li>Please enter a valid email address</li>
                                </ul>
                            }
                            <Input type="text" placeHolder="Email Address" className="login-page-input" valid={isValidEmail} onChange={setEmail} />
                        </div>

                        {/* Password */}
                        <div className="password-container">
                            
                            <Input type="password" placeHolder="Password" className="login-page-input" valid={true} onChange={setPassword} />
                            {!isValidCredentials && 
                                <p className="invalid-credentials">Invalid email or password.</p>
                            }
                            <p className="forgot-password-text" 
                                onClick={() => navigate("/forgot-password")
                            }>
                                Forgot password?
                            </p>
                        </div>

                        <div className="login-button-container">
                            {!showSpinner ? (
                                <Button kind="pill" onClick={validateUser}>
                                Login
                                </Button>
                            ) : (
                                <Button kind="pill">
                                    <Spinner />
                                </Button>
                            )}
                            
                        </div>

                        {/* <div className="login-help">
                            <p onClick={toggleAccountSignup}>Signup</p>
                            <p onClick={toggleForgotPassword}>Forgot Password?</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export default Login;