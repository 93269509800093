import React, {useState } from "react";

import "./BragCard.css";
import Logo from "../primitives/Logo/Logo";

import BragCardModal from "./BragCardModal";

const BragCard = ({trade, isModal = false}) => {

    const [showModal, setShowModal] = useState(false);

    const tokenId = trade.tokenId;
    const transactionSource = trade.txnSource;
    const profit = trade.profit;
    const exitPrice = trade.txnPrice;
    const entryPrice = exitPrice / (1 + (profit / 100));

    return(
        <>
            <div className={"bc"} onClick={() => setShowModal(true)}>
                <div className="bc-logo-wrapper">
                    <Logo isSideBarExpanded={true} isLandingPage={false} linkActive={false}/>
                </div>
                <div className="bc-header">
                    <h2 className="text-header bc-token">{tokenId}</h2>
                    <p className="text-xsmall bc-method">{transactionSource}</p>
                </div>
                <h1 className={`text-hero-large bc-profit-${profit > 0 ? 'green' : 'red'}`}>{profit}%</h1>
                <div className="bc-trade-detail-container">
                    <div className="bc-entry-wrapper">
                        <p className="text-base bc-trade-detail-title">Entry Price</p>
                        <p className="text-base bc-trade-detail-subtext">
                            {entryPrice < 1 ? `$${(entryPrice).toFixed(6)}` : entryPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                            
                        </p>
                    </div>
                    <div className="bc-exit-wrapper">
                        <p className="text-base bc-trade-detail-title">Exit Price</p>
                        <p className="text-base bc-trade-detail-subtext">
                            {parseFloat(exitPrice) < 1 ? `$${parseFloat(exitPrice).toFixed(6)}` : parseFloat(exitPrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                        </p>
                    </div>
                </div>
            </div>
           {showModal && <BragCardModal trade={trade} setShowModal={setShowModal} />}
        </>
    );
};

export default BragCard;