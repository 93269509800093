import React from "react";

import "./ContactUs.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";


const ContactUs = () => {
  return (
    <DashboardLayout page={"Contact Us"}>
      <div className="contact-us">
        <div className="contact-us-content">
          <p>
            We're here to assist you! If you have any questions, concerns, or just want to get in touch, feel free to reach out to our dedicated support team at
            <span className="contact-us-email-address"> support@chart.observer</span>.
          </p>
          <p>
            We value your feedback and are committed to providing you with the best assistance possible.
          </p>
          <p>
            Whether you're seeking help with our services, have technical inquiries, or want to share your thoughts, our support team is ready to respond promptly. Your satisfaction is our priority, and we look forward to hearing from you.
          </p>
          <p>
            Thank you for choosing Chart Observer!
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ContactUs;
