import React from "react";

import "./styles/PageFour.css";

import SampleStrategies from "../assets/sample-strategies.png";
import NewsletterImage from "../assets/newsletter_image.png";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PageFour = () => {
  return (
    <section className="page-four landing-page-padding">
      <div className="page-wrapper">
        <div className="page-four-header">
          <h1 className="text-heading text-dark-blue">
            Sample strategies and tutorials
          </h1>
          <div className="coming-soon coming-soon--desktop">coming soon...</div>
        </div>

        <div className="page-four-sample-strategies">
          <div className="page-four-column-one">

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/automating-crypto-trades/tradingview/alerts/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>1</h1>
              </div>
              <div className="process-content">
                <h2>Basic Tradingview indicator alerts <KeyboardArrowRightIcon/></h2>
                <p>
                  We offer a basic strategy as a learning guide. Not intended to
                  be profitable, just a way to understand the process
                </p>
              </div>
            </div>

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/automating-crypto-trades/tradingview/webhooks/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>2</h1>
              </div>
              <div className="process-content">
                <h2>How to send us your BUY/SELL alerts<KeyboardArrowRightIcon/></h2>
                <p>
                  Alerts can be sent to your mobile, email, sms, or sent
                  directly to a web site like ours, or even an exchange like
                  Coinbase.
                </p>
              </div>
            </div>

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/trading-algorithms/tradingview-examples/bot-examples/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>3</h1>
              </div>
              <div className="process-content">
                <h2>Suggestions for custom strategies<KeyboardArrowRightIcon/></h2>
                <p>
                  Once you are comfortable with Tradingview alerts, we can help
                  you identify ways to build custom strategies and test them.
                </p>
              </div>
            </div>
          </div>
          <div className="page-four-column-two">
            <div className="page-four-column-two-image-container">
              <img className="sample-strategies-image" src={SampleStrategies} alt="sample strategies" />
            </div>
          </div>
          <div className="coming-soon coming-soon--mobile">coming soon...</div>
        </div>
      </div>
      <div className="page-four-background-image-top-right">
        <img
          alt="background graphic 1"
          className="page-four-background-image-top-right-img"
          src={NewsletterImage}
        />
      </div>
      <div className="page-four-background-image-bottom-right">
        <img
          alt="background graphic 2"
          className="page-four-background-image-bottom-right-img"
          src={NewsletterImage}
        />
      </div>
    </section>
  );
};

export default PageFour;
