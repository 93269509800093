import React, { useState } from "react";

import "./WebhookUrlInstructions.css";

import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import WebhookSettings from "../../images/webhook_settings_light.png";
import WebhookNotifications from "../../images/webhook_notifications_light.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useAuth } from "../../hooks/useAuth";

const WebhookUrlInstructions = () => {
  const { user } = useAuth();

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const copyText = () => {
    setShowCopyMessage(false);

    const webhookURL = `https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/${(user && user.webhookId) ? user.webhookId : "xxxxxxx"}`
    navigator.clipboard.writeText(webhookURL)
      .then(() => {
        setShowCopyMessage(true);

        const timer = setTimeout(() => {
          setShowCopyMessage(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text.");
      })
  }

  return (
    <DashboardLayout page={"Webhook Instructions"}>
      <div className="webhook-url-instructions">
        <div className="webhook-url-instructions-content">
          <h2 className="webhook-info-title text-header">
            Instructions for Tradingview users
          </h2>
          <h2 className="webhook-bullet text-header">
            1. Create an alert that triggers at your desired trade execution
            price
          </h2>
          <h2 className="webhook-bullet text-header">
            2. In the alert <b>Settings</b> panel, find the <b>Message</b> box,
            enter the text below:
          </h2>

          <div className="webhook-alert-container">
            <img src={WebhookSettings} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <p className="text-base">
                &#123;
                <br></br> "tokenpar":"BTCUSD",
                <br></br> "count":"10",
                <br></br> "usdprice":"&#123;&#123;close&#125;&#125;",
                <br></br> "action":"buy",
                <br></br> "exchange":"&#123;&#123;exchange&#125;&#125;",
                <br></br> "user":"<span className="webhook-url-instructions-span">{(user && user.userId) ? user.userId : "xxxxxxx"}</span>"
                <br></br>&#125;
              </p>
            </div>
          </div>
          <h2 className="webhook-bullet text-header">
            3. In the alert <b>Notifications</b> panel, check the box for
            <span className="webhook-url-instructions-span"><b>Webhook URL</b></span>, and enter the text below:
          </h2>
          <div className="webhook-alert-container">
            <img src={WebhookNotifications} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <h2 className="code-snippet-header text-base">Your Webhook URL for ChartObserver:</h2>
              <div className="webhook-url-copy-container">
                <ContentCopyIcon className="copy-icon" onClick={copyText} />
                <p className="text-base">
                  <span className="webhook-url-instructions-span">https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/{(user && user.webhookId) ? user.webhookId : "xxxxxxx"}</span>
                </p>
              </div>
              {showCopyMessage && <p className="text-small">Copied to clipboard!</p>}
            </div>
          </div>
          <h2 className="webhook-bullet text-header">
            The above example will send our platform an alert to buy 10 BTC at
            the USD price when the current candle closes. Alternatively replace
            “buy” with “sell” to completely sell all of your open positions for
            that tokenpair. If you have custom-coded strategies, you can use
            Tradingview variables like &#123;&#123;ticker&#125;&#125; and
            &#123;&#123;strategy.order.action&#125;&#125; to dynamically define
            these variables. Keep in mind, all orders sent to our platform are
            “mock” orders, also called “paper-trading”.
          </h2>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WebhookUrlInstructions;
