import React, { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import { getCoinBaseData, getTransactionsByUserId, getUserBalance } from "../../helpers/api";
import { formatDataForGrid } from "../../helpers/formatOpenPostions";

import "./Dashboard.css";

import OpenPositions from "../../components/postions/OpenPositions";
import PortfolioMetrics from "../../components/portfolioMetrics/PortfolioMetrics";
import Spinner from "../../components/loadingAnimations/Spinner";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { OpenPositionsRow, OpenPositionsTable } from "../../components/Table/OpenPositionsTable";
import TradeInstructions from "../../components/TradeInstructions/TradeInstructions";
import Activity from "../../components/Activity/Activity";
import { groupTrades } from "../../helpers/groupTrades";

const Dashboard = () => {

  const { user } = useAuth();
  const { isMobile } = useTheme();

  const [openPositionData, setOpenPositionData] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const [closedTrades, setClosedTrades] = useState(null);

  const date = new Date();

  const fetchDataForTables = async () => {
    // this will call both API calls at once and await a return of the promise befor proceeding into the .then
    return Promise.all([
      getTransactionsByUserId(user.userId),
      getCoinBaseData(),
      getUserBalance(user.userId),
    ]).then(([transactionData, coinbaseData, userBalance]) => {
      // Deconstructing the reutrn array from .all into the data that is returned from the functions.
      setOpenPositionData(formatDataForGrid(transactionData, coinbaseData));
      setUserBalance(userBalance);

      // Finding Closed Trades and Storing data
      if (transactionData.length > 0) {
        setClosedTrades(groupTrades(transactionData, true));
      }
    });
  };

  useEffect(() => {
    if (user) {
      // console.log("calling use effect")
      fetchDataForTables().then();
    }
    // eslint-disable-next-line
  }, [user]);

  if (isMobile) {
    return(
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-content">
          <PortfolioMetrics openPositionData={openPositionData} userBalance={userBalance} />
          <h1 className="mobile-op-title">Open Positions</h1>
          <div className="mobile-table-updated">
            <p>last updated {date.toString()}</p>
          </div>
          {openPositionData && openPositionData.length > 0 ? (
            <OpenPositionsTable>
              {openPositionData.map((item, index) => (
                <OpenPositionsRow 
                  key={index} 
                  token={item.id.split('USD')[0]}
                  amount={item.amount}
                  costBasis={item.avgPrice * item.amount}
                  currentPrice={item.currentPrice}
                  currentValue={item.amount * item.currentPrice}
                  profit={item.profit}
                  avgPrice={item.avgPrice}
                />
              ))}
            </OpenPositionsTable>
          ) : openPositionData && openPositionData.length === 0 ? (
            <TradeInstructions />
          ) : (
            <Spinner containerClass={"spinner-table-container"} />
          )}
          {closedTrades &&
            <Activity closedTrades={closedTrades}/>
          }
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout page={"Dashboard"}>
      <div className="dashboard-content">
        {/* <Header /> */}
        <PortfolioMetrics openPositionData={openPositionData} userBalance={userBalance} />
        {openPositionData ? (
          <OpenPositions openPositionData={openPositionData} />
        ) : (
          <Spinner containerClass={"spinner-table-container"} />
        )}
        {closedTrades &&
          <Activity closedTrades={closedTrades}/>
        }
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
