

export const formatDataForGrid = (transactionData, coinbaseData) => {

    const openPositions = {};
    transactionData.forEach(trade => {
        var token = trade.tokenPair.S;
        if (trade.isOpen.BOOL) {
            if (openPositions.hasOwnProperty(trade.tokenPair.S)) {
                openPositions[token].push(trade);
            } else {
                openPositions[token] = [trade];
            }
        }
    })

    var formatedOpenPositionArray = []
    // Loop through Trade Tokens
    for (const key in openPositions) {
        var amount = 0;
        var totalCost = 0;
        var purchasePrices = [];
        if (openPositions.hasOwnProperty(key)) {
            // Loop through Trades for each token
            openPositions[key].forEach(trade => {
                amount += parseFloat(trade.amount.N);
                totalCost += (parseFloat(trade.txnPrice.N) * parseFloat(trade.amount.N));
                purchasePrices.push(parseFloat(trade.txnPrice.N));
            });
            // Split BTCUSD to BTC for coinbase API Data
            const coinbaseTokenName = key.split("USD")[0];
            const currentPrice = (coinbaseData) ? (1 / coinbaseData["data"]["rates"][coinbaseTokenName]) : "N/a";
            const profit = (currentPrice === "N/a") ? "N/a" : (((currentPrice * amount) - totalCost) / totalCost) * 100
            const tokenData = {
                id: key,
                amount: amount,
                totalCost: totalCost,
                avgPrice: (purchasePrices.reduce((total, num) => total + num, 0)) / purchasePrices.length,
                // Set value to null if coinbase data is missing
                currentPrice: currentPrice,
                profit: profit
            };
            
            formatedOpenPositionArray.push(tokenData); 

        }
    }

    return(formatedOpenPositionArray)
}