import React, { useEffect, useState } from "react";

import "./styles/Card.css";
import PieChart from "../nivoCharts/PieChart";
import HoldingsModal from "./modals/HoldingsModal";

import { useTheme } from "../../hooks/useTheme";

const HoldingsCard = ({ user, openPositionData, userBalance }) => {
  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const colors = [
    "#C6DAEF",
    "#9ECAE0",
    "#6AAED6",
    "#4192C5",
    "#2270B5",
    "#08519C",
    "#0A306B",
    "#F7FBFF",
    "#DDEBF7",
  ];
  const newArray = Array.from(openPositionData);
  newArray.forEach((token) => {
    token.id = token.id.split("USD")[0];
  });
  // Adding cash to the holding pie
  newArray.push({ id: "USD", totalCost: userBalance });
  // sort array on descending value
  newArray.sort((a, b) => b.totalCost - a.totalCost);

  const tokenList = [];
  const chartData = [];
  newArray.forEach((token, index) => {
    tokenList.push(token.id);
    chartData.push({
      id: token.id,
      label: token.id,
      value: token.id === "USD" ? token.totalCost : (token.amount * token.currentPrice),
      color: colors[index],
    });
  });

  const chartSettings = {
    margin: { top: 20, right: 40, bottom: 20, left: 40 },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsDiagonalLength: 10,
    arcLinkLabelsStraightLength: 8,
    enableArcLabels: false,
  };

  return (
    <div className="card" onClick={() => !isMobile && toggleModal()}>
      <h1 className="card-position-value text-hero">
        {parseFloat(userBalance).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        {" USD*"}
        <span className="text-header"> available</span>
      </h1>
      <div className="card-info">
        <div className="card-position-info">
          {/* <h1 className="card-position-value">{tokenList[0]}</h1> */}
          {/* List the top 3 tokens */}
          {/* {tokenList.slice(1, 4).map((token) => (
            <p key={token} className="card-position-value-subtext">
              {token}
            </p>
          ))} */}
        </div>
        {/* Nivo Pie Chart goes here */}
        <div className="card-chart-display">
          <PieChart chartData={chartData} chartSettings={chartSettings} />
        </div>
      </div>
      {modalState && (
        <HoldingsModal
          toggleModal={toggleModal}
          chartData={chartData}
          tokenList={tokenList}
        />
      )}
    </div>
  );
};

export default HoldingsCard;
