import React from "react";
import "./DashboardFooter.css";
import { Link } from "react-router-dom";

const DashboardFooter = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="dashboard-footer">
      <nav>
        <ul>
          <li>
            <Link to="/terms-of-service">Terms of Service</Link>
          </li>
          <li>
            <Link to="/terms-of-service">Privacy</Link>
          </li>
          <li>
            <Link to="/terms-of-service">Contact Us</Link>
          </li>
        </ul>
      </nav>
      <div className="dashboard-footer-copyright">
        {/* Copyright 2023 <a href="#">Rendition Multimedia</a> */}
        Copyright {currentYear} Rendition Multimedia, Inc.
      </div>
    </footer>
  );
};

export default DashboardFooter;
