/**
 * Validates if the given username is of the proper format.
 * @param {string} username - The username to validate.
 * @returns {boolean} - True if the username is valid, false otherwise.
 */
export const validateUsername = (username) => {
    // Your username validation logic here
    const usernameRegEx = /^[a-zA-Z0-9]+$/;
    return usernameRegEx.test(username);
}
  