import React, { useState } from "react";

import "./TopBar.css";
import PersonIcon from "@mui/icons-material/Person";
// import SettingsIcon from "@mui/icons-material/Settings";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import Brightness4Icon from '@mui/icons-material/Brightness4';

import { UserInfoModal } from "../ModalController/UserInfoModal";

import { useTheme } from "../../hooks/useTheme";
import { DiscordIcon } from "../CustomIcons/DiscordIcon";

const TopBar = ({page}) => {

  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  const handleModalClose = () => {
    setShowUserInfoModal(false);
  };
 
  const { toggleDarkMode, toggleSideBar } = useTheme();

  return (
    <div className="topbar-wrapper">
      <div className="topbar-container">
        <h2 className="topbar-title text-header">{page}</h2>
        <div className="topbar-button-container">
          <DiscordIcon className="topbar-icon"/>
          <AutoAwesomeMosaicIcon className="topbar-icon" onClick={() => toggleSideBar()}/>
          <Brightness4Icon className="topbar-icon" onClick={toggleDarkMode}/>
          {/* <NotificationsIcon className="topbar-icon" /> */}
          {/* <SettingsIcon className="topbar-icon" /> */}
          <PersonIcon
            className="topbar-icon"
            onClick={() => setShowUserInfoModal(true)}
          />
        </div>
        {showUserInfoModal && <UserInfoModal handleModalClose={handleModalClose}/>}
      </div>
      {/* <hr className="topbar-divider" /> */}
    </div>
  );
};

export default TopBar;
