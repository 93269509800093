import React from "react";
import "./styles/PageTwo.css";

import Box from "../assets/box.png";
import Calendar from "../assets/calendar.png";
import Statistics from "../assets/statistics.png";

const PageTwo = () => {
  return (
    <section className="page-two landing-page-padding">
      <div className="page-wrapper">
        <div className="page-two-header">
          <h1 className="page-two-title text-heading text-dark-blue">
            Process Alerts from Tradingview
          </h1>
          <h2 className="page-two-sub-title">
            Or manually enter BUY and SELL orders{" "}
          </h2>
        </div>
        <div className="page-two-card-container">
          <div className="page-two-card">
            <div className="card-header">
              <img src={Box} alt="" />
            </div>
            <h2>Immediately enter a trade</h2>
            <p>
              No Tradingview? No problem. Trial users can manually execute
              simulated trades.
            </p>
          </div>
          <div className="page-two-card">
            <div className="card-header">
              <img src={Calendar} alt="" />
            </div>
            <h2>Send us Tradingview alerts</h2>
            <p>
              Automated BUY & SELL alerts are easy to setup if you have a
              Tradingview login. We are built to receive them.
            </p>
          </div>
          <div className="page-two-card">
            <div className="card-header">
              <img src={Statistics} alt="" />
            </div>
            <h2>Adjust your strategy</h2>
            <p>
              Are you timing your trades well? Track them with our platform
              before you commit real money.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTwo;
