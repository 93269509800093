import React, { useState } from "react";
import "./ResetPassword.css";
import Logo from "../../components/primitives/Logo/Logo";
import Button from "../../components/primitives/Button/Button";
import Input from "../../components/primitives/Input/Input";
import { useLocation, useNavigate } from "react-router";
import { validatePassword } from "../../helpers/passwordValidation";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from "axios";

import { useAuth } from "../../hooks/useAuth";

const ResetPassword = () => {

    const navigate = useNavigate();

    const { login } = useAuth();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isMatchingPassword, setIsMatchingPassword] = useState(true);

    const [InavlidCredentials, setInavlidCredentials] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const otp = queryParams.get("otp");
    const email = atob(queryParams.get("user"));

    const checkPasswords = () => {
        // console.log("New Password: ", newPassword);
        // console.log("Confirm Password: ", confirmPassword);
        if (newPassword !== confirmPassword) {
            setIsMatchingPassword(false);
        }

        if ( validatePassword(newPassword) && newPassword === confirmPassword) {
            // console.log("Pass");
            setIsValidPassword(true);
            setIsMatchingPassword(true);
            return true;
        } else {
            // console.log("Fail");
            setIsValidPassword(false);
            return false;
        }
    };

    const resetPassword = async () => {
        // Check for password requirements
        if (checkPasswords()) {
            const url = "https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/user/password/reset"

            const data = {
                newpass: newPassword,
                otp: otp
            }
            const headers = {
                "Content-Type": "application/json",
              };
              
            axios
                .post(url, data, { headers })
                .then((response) => {
                    // console.log(response)
                    if(response.status === 200) {
                        // On Password Reset Success - Log in user
                        login(email, newPassword, setInavlidCredentials);
                    }
                })
                .catch((error) => {

                    if (error.response.status === 400) {
                        alert("There is no user with associated with this one-time password. Please request another password or contact support@chart.observer.")
                    } else {
                        // console.log("Could not reset password")
                        alert("Could not reset password, please request a new password or contact support@chart.observer.");
                    }
                })
        }

    }

    // const handleKeyDown = (event) => {
    //     if (event.key === "Enter") {
    //         resetPassword();
    //       }
    // };

    return(
        <div className="reset-password-page">
            <div className="reset-password-background">
                <div className="reset-password-container">
                    <Logo isSideBarExpanded={true} isLandingPage={true} />
                    <h1>Reset Password</h1>
                    <h3 className="reset-password-email">{email}</h3>
                    <ul>
                        <li>At least one Uppercase</li>
                        <li>At least one Lowercase</li>
                        <li>At least one Number</li>
                        <li>At least one Special Character: #?!@$%^&*-</li>
                    </ul>
                    
                    <Input type="password" placeHolder="New Password" valid={isValidPassword} onChange={setNewPassword} />

                    <Input type="password" placeHolder="Confirm Password" valid={isValidPassword} onChange={setConfirmPassword} />
                    {!isMatchingPassword && <p className="matching-password-error">Passwords do not match.</p>}
                    
                    <div className="reset-password-button-container">
                        <Button kind="pill" onClick={resetPassword}>Reset</Button>
                    </div>

                    <div className="back-to-login" onClick={() => navigate("/dashboard")}>
                        <ArrowBackIosNewIcon sx={{ fontSize: 16, color: "grey"}}/>
                        <p>Back to Dashboard</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;